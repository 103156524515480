<template>
    <div class="global-content">
        <filtrate type="warn" />
        <div class="inner">
            <div v-if="loadingInfo">
                <van-loading class="van-load-z" size="24px">加载中...</van-loading>
            </div>
            <div v-else>
                <div v-if="!emptyData">
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
                        <div class="part" v-for="(item,index) in dataList" :key="index">
                            <div class="head">
                                <div>名称：{{item.devName}}</div>
                                <div>编号：{{item.devCode}}</div>
                            </div>
                            <div class="block">
                                <div class="flex">
                                    <div class="type">类型：{{item.msgType =='devFaultAlarm'?'设备故障告警':item.msgType == "powerLowAlarm" ? '设备缺电告警' : item.msgType == "proLostAlarm" ? '节目单丢失告警' :item.msgType == "waterFreeseAlarm" ? '积水结冰告警' :item.msgType == "damageAlarm" ? '倾角告警' :item.msgType == "powerDownAlarm" ? '设备掉电告警' :item.msgType == "fenceAlarm" ? '电子围栏告警' :item.msgType == "helpAlarm" ? '现场求助告警' :'应急车道停车告警'}}</div>
                                </div>
                                <div class="flex">位置：{{item.devRoad || '未添加地址'}}</div>
                                <div class="time">更新时间：{{item.uploadTime}}</div>
                            </div>
                        </div>
                    </van-list>
                </div>
                <div v-else><van-empty description="没有更多数据了" /></div>
            </div>
        </div>
    </div>
</template>
<script>
import filtrate from '@/components/itgs/filtrate.vue'
export default {
    name:'Warn',
    components: {
        filtrate
    },
    data(){
        return{
            loadingInfo:true,
            emptyData:false,
            dataList:[],
            loading:false,
            finished:false,
            pageNum:1,
            pageSize:10,
            filter:{
                devCode:'',
                typeId:3,
                start:'',
                end:'',
            }
        }
    },
    mounted(){
        this.onLoad();
    },
    methods:{
        reload(param){
            this.filter.typeId = param.typeId;   //根据typeId判断哪种类型
            this.filter.devCode = param.devCode;
            this.filter.start = param.start;
            this.filter.end = param.end;
            this.dataList = [];
            this.finished = false;
            this.pageNum = 1; //筛选时间  重置页码
            this.onLoad();
        },
        // 上拉加载
        onLoad(){
            const projectCode = window.sessionStorage.getItem('projectCode');
            let postData = {
                beginTime :this.filter.start,
                endTime :this.filter.end,
                devCode:this.filter.devCode,
                projectCode:projectCode
            } 
            switch(this.filter.typeId){
                case 1:  //设备缺电告警
                    this.$api.ITGS.AlarmPowerLow(this.pageNum,this.pageSize,postData).then((data)=>{
                        this.loadlater(data);
                    })
                    break;
                case 2: //节目单丢失告警
                    this.$api.ITGS.AlarmProLost(this.pageNum,this.pageSize,postData).then((data)=>{
                        this.loadlater(data);
                    })
                    break;
                case 3: //设备故障告警
                    this.$api.ITGS.AlarmDevFault(this.pageNum,this.pageSize,postData).then((data)=>{
                        this.loadlater(data);
                    })
                    break;
                // case 4: //积水结冰告警数据
                //     this.$api.ITGS.AlarmWaterFreese({
                //         pageNum :this.pageNum,
                //         pageSize :this.pageSize,
                //         data:postData
                //     }).then((data)=>{
                //         this.loadlater(data);
                //     })
                //     break;
                case 5: //倾角告警
                    this.$api.ITGS.AlarmDamage(this.pageNum,this.pageSize,postData).then((data)=>{
                        this.loadlater(data);
                    })
                    break;
                case 6: //设备掉电事件告警
                    this.$api.ITGS.AlarmPowerDown(this.pageNum,this.pageSize,postData).then((data)=>{
                        this.loadlater(data);
                    })
                    break;
                case 7: //电子围栏事件告警
                    this.$api.ITGS.AlarmFence(this.pageNum,this.pageSize,postData).then((data)=>{
                        this.loadlater(data);
                    })
                    break;
                case 8: //现场求助事件告警
                    this.$api.ITGS.AlarmHelp(this.pageNum,this.pageSize,postData).then((data)=>{
                        this.loadlater(data);
                    })
                    break;
                case 9: //应急车道停车事件告警
                    this.$api.ITGS.AlarmCarStop(this.pageNum,this.pageSize,postData).then((data)=>{
                        this.loadlater(data);
                    })
                    break;
            }
            
        },
        //封装判断分页之后 后续操作
        loadlater(data){
            this.loadingInfo = false;
            this.dataList = this.dataList.concat(data);
            this.dataList.length === 0 ? this.emptyData = true : this.emptyData = false;
            data.length < this.pageSize ? this.finished = true : void 0 ;
            this.loading = false;
            this.pageNum ++;
        }
    }
}
</script>
<style lang="scss" scoped>
.van-empty{margin: 25% 0;}
.inner{
    .van-field__body{color:#969799 !important}
    .part{margin-top: 2%;}
    .head{
        display: flex;
        justify-content: space-between;
        font-size: .8rem;
        padding: 2% 4%;
        background-image: linear-gradient(141deg,#0474ff 0%,#59a8f9 51%,#54a7fd 75%);
        color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-weight: bold;
    }
    .block{
        padding: 0 4% 4% 4%;
        background-color: #fff;
        font-size: .8rem;
        border: 1px solid #ececec;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .flex{
            display: flex;
            justify-content:space-between;
            margin: 2% 0;
            font-weight: bold;
        }
        .time{color: #656565;margin-top:2%;}
    }
}
.van-load-z{margin: 8% auto;text-align: center;}
</style>